import styled from '@emotion/styled';
import {
  BaseballJackpotRedContainer,
  BaseballCountupContainer,
} from 'assets/images';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  width: 50%;
  max-width: 400px;
  z-index: 4;
  font-size: 30pt;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .rays {
    animation: spin 4s linear infinite;
    z-index: 3;
    width: 150%;
    height: 150%;
    top: -25%;
    left: -25%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    &.trophy {
      animation: noticeMe 0.5s linear infinite;
      z-index: 3;
      width: 75%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes noticeMe {
    0% {
      width: 75%;
      height: 100%;
    }

    50% {
      width: 50%;
      height: 75%;
    }

    100% {
      width: 75%;
      height: 100%;
    }
  }

  @media screen and (orientation: landscape) {
    width: 15%;
  }
`;

export const HomeRunContainer = styled.div`
  display: block;
  position: relative;

  .home-run {
    position: relative;
  }
`;

export const HomeRunBackground = styled.img`
  position: absolute;
  top: -10%;
  left: -5%;
  right: 0;
  bottom: 0;
  width: 110% !important;
  height: 125% !important;
`;

export const WinLight1 = styled.img`
  z-index: 4 !important;
  position: absolute;
  bottom: -48%;
  left: -12%;
`;

export const WinLight2 = styled.img`
  z-index: 4 !important;
  position: absolute;
  top: -48%;
  right: -12%;
`;

export const Jackpot = styled.div`
  text-shadow: 0px 1px 0 #050510;
  font-family: 'Barlow Semi Condensed Bold';
  font-size: 26px;
  font-weight: bold;
  font-stretch: semi-condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.3px;
  text-align: center;
  color: #fff;
  background-image: url(${BaseballJackpotRedContainer});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  width: 60%;
  height: 66px;
  margin: auto;
  z-index: 5;
  margin-top: -27px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    transform: rotateZ(-7deg);
    font-size: 2vw;
  }

  ${(prop) =>
    prop.hide
      ? `
    opacity: 0;
  `
      : null};
`;

export const Countup = styled.div`
  background-image: url(${BaseballCountupContainer});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 7vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TrophyContainer = styled.div`
  margin-top: 75%;
  position: relative;
  display: flex;
  width: 100%;
  aspect-ratio: 1;

  .glitter {
    width: 40px;
    height: 40px;
    position: absolute;

    &.g1 {
      top: 50px;
    }

    &.g2 {
      top: 50px;
      right: 0;
    }

    &.g3 {
      top: 50px;
      left: 50%;
    }

    &.g4 {
      bottom: 50px;
      right: 0;
    }

    &.g5 {
      top: 150px;
      left: 50px;
    }

    &.g6 {
      top: 50px;
      right: 50px;
    }

    &.g7 {
      top: 100px;
      right: 22%;
    }
  }
`;
